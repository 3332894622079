//imports
import React, { useRef, useEffect, useState } from 'react';
import '../index.css'
import mapboxgl from 'mapbox-gl';
//Constants
mapboxgl.accessToken = 'pk.eyJ1IjoiamFtZXNiZXN0ZXIiLCJhIjoiY2t1NmhnMTlkNWFtbDJ2bzJvaDV2MHN1MiJ9.Puw8sJNEnDYvULGp0odUhQ';
const api = {
  key: 'f40fdbed4ce6f49a21c55972b85d4f67',
  base: "https://api.openweathermap.org/data/2.5/"
}


export default function Map(){
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lng, setLng] = useState(144.9631);
  const [lat, setLat] = useState(-37.8182);
  const [zoom, setZoom] = useState(10);
  const [query, setQuery] = useState('Melbourne, Au');
  const [currentTemp, setCurrentTemp] = useState('');
  const [currentCity, setCurrentCity] = useState('Melbourne, AU')

  useEffect(() =>{
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/jamesbester/cku6hsltb3l0418oem2xrjbs8',
      center: [ lng, lat ],
      zoom: zoom
    });
  });

  useEffect(() => {
    if(!map.current) return;
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    })
    
  })
  useEffect(() => {
    fetch(`${api.base}weather?q=Melbourne,Au&units=metric&APPID=${api.key}`)
      .then(res => res.json())
      .then(result => {
          if (result.cod !== "404"){
          console.log(result)
          setQuery('')
          setLng(result.coord.lon)
          setLat(result.coord.lat)
          setCurrentCity(result.name + ", " + result.sys.country)
          setCurrentTemp(result.main.temp)
          map.current.flyTo({
            center: [result.coord.lon,result.coord.lat]
          })
        }else{
          alert("city not found")

      }
      })   
  }, [])
  
  const el = document.createElement('div');
  el.className = 'marker';

  const mark = () =>{
    const marker1 = new mapboxgl.Marker(el)
    .setLngLat([lng, lat])
    .addTo(map.current);
    const lnglat = marker1.getLngLat()

    console.log(`Lng: ${lnglat.lng}, lat: ${lnglat.lat}`);
    
  }

  const getLocation= e => {
    if(e.key === 'Enter'){
      fetch(`${api.base}weather?q=${query}&units=metric&APPID=${api.key}`)
      .then(res => res.json())
      .then(result => {
          if (result.cod !== "404"){
          console.log(result)
          setQuery('')
          setLng(result.coord.lon)
          setLat(result.coord.lat)
          setCurrentCity(result.name + ", " + result.sys.country)
          setCurrentTemp(result.main.temp)
          map.current.flyTo({
            center: [result.coord.lon,result.coord.lat]
          })
        }else{
            alert("city not found")

        }
        })             
      }
    };

  return (
    <div>
      <div className="coords-container">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} | location: {currentCity} | Temp: {currentTemp}°c
        <input type="text" className="search" placeholder="e.g Melbourne, AU" value={query} onChange={e => setQuery(e.target.value)} onKeyDown={getLocation} />
      </div>
      <div ref={mapContainer} className="map-container" />
    </div>
    );
}