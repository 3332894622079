import './App.css';
import { CssBaseline, ThemeProvider  } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {Route, Routes} from 'react-router-dom'; 
import Resume from './pages/Resume';
import Projects from './pages/projects';
import PasswordGenerator from './pages/Projects/passwordGenerator';
import WeatherApp from './pages/Projects/WeatherApp';
import Map from './pages/Projects/map';
import Home from './pages/home';
import Blog from './pages/Blog';
import Leetcode from './pages/leetcode';
import Tictactoe from './pages/Projects/tictactoe';

function App() {
  const [theme, colorMode ] = useMode()
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route path="/" element={<Blog />} />
            <Route path="/resume" element={<Resume />}/>
            <Route path="/projects" element={<Projects />}/>
            <Route path="/leetcode" element={<Leetcode />} />
            <Route path="/projects/passwordGen" element={<PasswordGenerator />} />
            <Route path="/projects/weatherApp" element={<WeatherApp />}/>
            <Route path="/projects/searchMap" element={<Map />} />
            <Route path="/projects/TikTacToe" element={<Tictactoe />} />
          </Routes>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
