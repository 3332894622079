import React, { Component } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Header from './Header';
import Footer from './Footer';
export default function Resume() {
    const defaultTheme = createTheme();
    const sections = [
        { title: 'Home', url: '/'},
        { title: 'Projects', url: '/projects' },
        { title: 'LeetCode', url: '/leetcode' },
        { title: 'Resume', url: '/resume' },
        { title: 'About', url: '/about' },
        { title: 'Posts', url: '/posts' },
        { title: 'Photography', url: '/photography' },
        { title: 'Arduino Projects', url: '/arduino' },
      ];
    return(
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container maxWidth="xl">
            <Header title="JamBotics" sections={sections} current="Home" />
                <iframe style={{width: '100%', height: '90vh'}} src='../assets/James Bester (1).pdf'/>
            </Container>
            <Footer
        title="Jambotics"
        description="under construction..."
      />
        </ThemeProvider>
        )
}