import { Box, IconButton, Typography, useTheme, Container, CssBaseline, Grid, Paper, styled} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header'
import Footer from './Footer'
import MainFeaturedPost from './MainFeaturedPost'
import FeaturedPost from './FeaturedPost';
import Post from '../db/posts.json'



const Leetcode = 
() => {
    const theme = useTheme();
    const defaultTheme = createTheme();
    const sections = [
        { title: 'Home', url: '/'},
        { title: 'Projects', url: '/projects' },
        { title: 'LeetCode', url: '/leetcode' },
        { title: 'Resume', url: '/resume' },
        { title: 'About', url: '/about' },
        { title: 'Posts', url: '/posts' },
        { title: 'Photography', url: '/photography' },
        { title: 'Arduino Projects', url: '/arduino' },
      ];
    const leet = Post.posts.filter( data => data.tag == "leetcode")
    const Posts = ({png, title,date, description, uri}) => {
        let obj ={
            title: title,
            date: date,
            description: description,
            png: png,
            imageLabel: 'LeetCodeScreenShot',
            uri: uri
          }
        return(
            <FeaturedPost key={obj.title} post={obj} />
        )
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline>
                <Container maxWidth="xl">
                    <Header title="JamBotics" sections={sections} current="LeetCode" />
                    <Grid container spacing={3} sx={{ mt: 3}}>
                    {leet.map(item => {
                            return(
                                <Posts title={item.name} link={item.link} description={item.description} uri={item.uri} png={item.png} />
                                )
                        })}
                    </Grid>
                </Container>
                <Footer
        title="Jambotics"
        description="under construction..."
      />
            </CssBaseline>
        </ThemeProvider>            
        )

}

export default Leetcode;