import { CssBaseline } from '@mui/material'
import React, { Component } from 'react'
import { useEffect } from 'react'

export default function Blog() {

  useEffect(() => {
    window.location.href = "https://jamesthomasbester.github.io/WeatherApp/"
  }, [])
  
    return (
      <CssBaseline>
      </CssBaseline>
    )

}
