import React, { useState } from 'react'
import { Box, IconButton, useTheme, Typography, Container, CssBaseline, Checkbox, FormControl, FormGroup, FormControlLabel, TextField, Button} from '@mui/material'
import { tokens } from '../../theme';
import Words from '../../util/data/words_dictionary'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../Header';
import Footer from '../Footer'

export default function PasswordGenerator() {
  const [special, setSpecial] = useState(false)
  const [numeric, setNumeric] = useState(false)
  const [upper, setUpper] = useState(false)
  const [word, setWord] = useState(false)
  const [length, setLength] = useState(5)
  const [password, setPassword] = useState('')
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const defaultTheme = createTheme();
  const sections = [
    { title: 'Home', url: '/'},
    { title: 'Projects', url: '/projects' },
    { title: 'LeetCode', url: '#' },
    { title: 'Resume', url: '/resume' },
    { title: 'About', url: '#' },
    { title: 'Posts', url: '#' },
    { title: 'Photography', url: '#' },
    { title: 'Arduino Projects', url: '#' },
  ];

  const Lists = (n, k) => {
    let alpha = Array.from(Array(n)).map((e, i) => i + k);
    let beta = alpha.map((x) => String.fromCharCode(x));
    return (beta);
  }
  console.log()
  const invertBoolean = (input) => {
    switch(input){
      case 'numeric':
        setNumeric(current => !current)
        break;
      case 'special':
        setSpecial(current => !current)
        break;
      case 'upper':
        setUpper(current => !current)
        break;
      case 'word':
        setWord(current => !current)
        break;
      default:
        break;
    }
    console.log(special)
    console.log(numeric)
    console.log(upper)
    console.log(word)
    console.log(length)
  }

  const generatePass = () => {
    if(!(length >= 7)){

      return
    }
    var password = []
    let numbers = Lists(10, 48)
    let chars = Lists(26, 65)
    let specials = Lists(15, 33)

    const options = {
      word: word,
      numeric: numeric,
      special: special,
      upper: upper
    }
    
    console.log(options)
    switch(true){
      case word && upper && numeric && special: 
        let results = Words.nouns.filter(item => item.length == length-2)
        password.push(results[Math.round(Math.random() * results.length-1)])
        password = password.toString().split('')
        password[0] = password[0].toUpperCase()
        password.push(specials[Math.floor(Math.random() * specials.length)])
        password.push(numbers[Math.floor(Math.random() * numbers.length)])
        break;
      case word && upper && numeric:
        {
          let results = Words.nouns.filter(item => item.length == length-1)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
          password[0] = password[0].toUpperCase()
          password.push(numbers[Math.floor(Math.random() * numbers.length)])
        }
        break;
      case word && upper && special:
        {
          let results = Words.nouns.filter(item => item.length == length-1)
          console.log(results)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
          password[0] = password[0].toUpperCase()
          password.push(specials[Math.floor(Math.random() * specials.length)])
        }
        break;
      case word && upper: 
        {
          let results = Words.nouns.filter(item => item.length == length)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
          password[0] = password[0].toUpperCase()
        }
        break;
      case word && special && numeric:
        {
          let results = Words.nouns.filter(item => item.length == length-2)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
          password.push(specials[Math.floor(Math.random() * specials.length)])
          password.push(numbers[Math.floor(Math.random() * numbers.length)])
        }
        break;
      case word && special:
        {
          let results = Words.nouns.filter(item => item.length == length-1)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
          password.push(specials[Math.floor(Math.random() * specials.length)])
        }
        break;
      case word && numeric:
        {
          let results = Words.nouns.filter(item => item.length == length-1)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
          password.push(numbers[Math.floor(Math.random() * numbers.length)])
        }
        break;
      case word:
        {
          let results = Words.nouns.filter(item => item.length == length)
          password.push(results[Math.round(Math.random() * results.length-1)])
          password = password.toString().split('')
        }
        break;
      default :
          for (let i = 0; i < length; i++){
            password.push(chars[Math.floor(Math.random() * chars.length)].toLowerCase())
          }
          break;
    }
    console.log(password.join(""))
    setPassword(password.join(""))
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline >
        <Container maxWidth="lg" >
        <Header title="JamBotics" sections={sections} current="Projects" />
          <Box sx={{ justifyContent: 'center', width: '100%', height: '100%', paddingTop: '100px' }} >
            <Box padding='120px'>
              <Typography variant='h4'>Password Requirments</Typography>
              <Box justifyContent='center' alignItems='center' display='block' margin='20px'>
                <FormControl >
                  <FormGroup row>
                    <FormControlLabel label="numeric" control={<Checkbox onChange={() => {invertBoolean('numeric')}} color="success" />} />
                    <FormControlLabel color='secondary' label="special" control={<Checkbox onChange={() => {invertBoolean('special')}} color="secondary" />} />
                    <FormControlLabel label="upper case" control={<Checkbox onChange={() => {invertBoolean('upper')}} color="default" />} />
                    <FormControlLabel label="use words" control={<Checkbox onChange={() => {invertBoolean('word')}} sx={{'&.Mui-checked': {color: 'pink'}}} />} />
                    <TextField color={(length >= 7) ? "secondary" : "error"} helperText={(length >= 7) ? "" : "minimum length is 7"} fullWidth label="length" onChange={(e) => {setLength(e.target.value)}} />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box>
                <TextField color='secondary' label="password" value={password} fullWidth sx={{ marginBottom: '20px' }}/>
                <Button variant="outlined" color='secondary'  onClick={() => {generatePass()}} >Generate</Button>
              </Box>

            </Box>
          </Box>
        </Container>
        <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
      </CssBaseline>
    </ThemeProvider>
  )
}
