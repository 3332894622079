import { Box, IconButton, Typography, useTheme, Container, CssBaseline, Grid, Paper, styled} from '@mui/material'
import { tokens } from '../theme';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header'
import Footer from './Footer'
import MainFeaturedPost from './MainFeaturedPost'
import Posts from '../db/posts.json'
import { useEffect } from 'react';

const Projects = 
() => {
    const theme = useTheme();
    const defaultTheme = createTheme();
    const colors = tokens(theme.palette.mode)
    const sections = [
        { title: 'Home', url: '/'},
        { title: 'Projects', url: '/projects' },
        { title: 'LeetCode', url: '/leetcode' },
        { title: 'Resume', url: '/resume' },
        { title: 'About', url: '/about' },
        { title: 'Posts', url: '/posts' },
        { title: 'Photography', url: '/photography' },
        { title: 'Arduino Projects', url: '/arduino' },
      ];

      const projects = Posts.posts.filter( data => data.tag == "project")
    const Proj = ({link, title, description, uri}) => {
        const mainFeaturedPost = {
            title: title,
            description: description,
            image: `../assets/${link}.png`,
            imageText: 'main image description',
            linkText: 'Check it out…',
            link: `/${uri}`
          };
        return(
            <MainFeaturedPost post={mainFeaturedPost} />

        )
    }
    useEffect(() => {
        let item = Posts.posts.filter( data => data.tag == "project")
        console.log(item)
    }, [])

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline>
                <Container maxWidth="xl">
                <Header title="JamBotics" sections={sections} current="Projects" />
                {projects.map(item => {
                            return(
                                <Proj title={item.title} link={item.png} description={item.description} uri={item.uri} />
                                )
                        })}
                </Container>
                <Footer
        title="Jambotics"
        description="under construction..."
      />
            </CssBaseline>
        </ThemeProvider>            
        )

}

export default Projects;