import { CssBaseline } from '@mui/material'
import React, { Component } from 'react'

export class home extends Component {
  render() {
    return (
      <CssBaseline>
        
      </CssBaseline>
    )
  }
}

export default home