import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import CodeIcon from '@mui/icons-material/Code';
import InstagramIcon from '@mui/icons-material/Instagram';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Posts from '../db/posts.json'
import { useEffect } from 'react';

const sections = [
  { title: 'Home', url: '/'},
  { title: 'Projects', url: '/projects' },
  { title: 'LeetCode', url: '/leetcode' },
  { title: 'Resume', url: '/resume' },
  { title: 'About', url: '/about' },
  { title: 'Posts', url: '/posts' },
  { title: 'Photography', url: '/photography' },
  { title: 'Arduino Projects', url: '/arduino' },
];

const mainFeaturedPost = {
  title: 'Search Map',
  description:
    "Little project that i started that displays a map, with relevant coordinate data and weather for this location",
  image: '../assets/searchMap.png',
  imageText: 'main image description',
  linkText: 'Continue reading…',
  link: '/Projects/searchMap'
};

const featuredPosts = [
  {
    title: 'Leet Code',
    date: 'Feb 11',
    description:
      '2620. Counter - Solved',
    png: '2620',
    imageLabel: 'LeetCodeScreenShot',
    uri: 'test'
  },
  {
    title: 'Telescope Auto-Focuser',
    date: 'Feb 09',
    description:
      'Continual development of the auto-focuser element that i am building for my telescope',
    png: 'telescope',
    imageLabel: 'Fusion360model',
  },
];

const posts = Posts.posts.sort((a,b) => {
  return new Date(a.date) - new Date(b.date)
}).slice(-6)

const sidebar = {
  title: 'About',
  description:
    'A dev ops engineer, based in Melbourne. I have an interest in anything and everything technology related and i am using this sapce to express and document my interests.',
  archives: [

  ],
  social: [
    { name: 'GitHub', icon: GitHubIcon, link: 'https://github.com/jamesthomasbester' },
    { name: 'instagram', icon: InstagramIcon, link: 'https://www.instagram.com/jamesthomasbester/' },
    { name: 'leetcode', icon: CodeIcon, link: 'https://leetcode.com/jamesthomasbester/' },
  ],
};



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Blog() {

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Header title="JamBotics" sections={sections} current="Home" />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="Newest Posts" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer
        title="Jambotics"
        description="under construction..."
      />
    </ThemeProvider>
  );
}
